import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import stringWithoutSpaces from '../../services/PropTypes/stringWithoutSpaces';

function Form(props) {
   const { defaultValues, children, onSubmit, watchValue, onWatch, className, getRef, formStyle } =
      props;

   const formRef = useRef(null);
   const {
      handleSubmit,
      errors,
      register,
      watch,
      setValue,
      getValues,
      setError,
      trigger,
      clearErrors,
   } = useForm({
      defaultValues,
   });

   useEffect(() => {
      // si queremos obtener la referencia a este componente
      // se utiliza la prop getRef
      if (getRef) {
         // guardamos en la ref las funciones que queremos utilizar
         formRef.current = {
            register,
            getValues,
            setValue,
            setError,
            trigger,
            clearErrors,
            handleSubmit,
         };
         // retornamos la ref para poder utilizarla
         getRef(formRef);
      }
   }, []);

   if (watchValue) {
      if (Array.isArray(watchValue)) {
         watchValue.forEach((value) => {
            useEffect(() => {
               const _watch = watch(value);
               onWatch(_watch, value);
            }, [watch(value)]);
         });
      } else {
         useEffect(() => {
            // guardamos el valor del input
            const _watch = watch(watchValue);
            // lo devolvemos en la func. onWatch
            onWatch(_watch);
            // como dependencia usamos el mismo watch
            // de este modo el effect solo se va a ejecutar
            // cuando el valor cambie
         }, [watch(watchValue)]);
      }
   }

   // renderizamos los children que pasemos en <Form>...</Form>
   const renderChildren = () => {
      if (Array.isArray(children)) {
         // iteramos todos los children
         return children.map((child) => {
            // verificamos si es un input (tiene prop. name="")
            if (child && child.props.name) {
               // retornamos un componente y le pasamos las prop. que ya tenia
               // pero ademas el register (para poder vincularlo con el form)
               // y error para mostrar errores
               return React.createElement(child.type, {
                  ...child.props,
                  key: child.props.name,
                  error: errors[child.props.name]?.message,
                  register,
                  clearErrors,
               });
            }

            // si no es input, devolvemos el children original
            return child;
         });
      }

      return React.createElement(children.type, {
         ...children.props,
         key: children.props.name,
         error: errors[children.props.name]?.message,
         register,
         clearErrors,
      });
   };

   const formClass = cn({
      [className]: !!className,
   });

   return (
      <div className={formClass}>
         <form onSubmit={handleSubmit(onSubmit)} className={formStyle}>
            {renderChildren()}
         </form>
      </div>
   );
}

Form.propTypes = {
   onSubmit: PropTypes.func.isRequired,
   className: stringWithoutSpaces,
};

Form.defaultProps = {
   className: null,
};

export default Form;
